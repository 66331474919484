@import '@styles';

$apply-select-roles: primary success warning danger;

// label
@mixin label-roles($label) {
    #{$label} {
        @include palette(font light base-dark);
        //@include palette(font main gray-2);

        @include theme(dark) {
            @include palette(font light gray-3);
        }
    }

    &:focus-within {
        #{$label} {
            @include palette(font light base-dark);
            //@include palette(font main gray-3);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$label} {
            @include palette(font light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font light danger);
            }
        }

        &:focus-within {
            #{$label} {
                @include palette(font light danger);
                //@include palette(font main danger);

                @include theme(dark) {
                    @include palette(font light danger);
                }
            }
        }
    }
}

// content
@mixin content-roles($content) {
    #{$content} {
        @include palette(bg light base, border light gray);
        outline: none;

        @include theme(dark) {
            @include palette(bg light base-dark, border light base-dark);
        }

        &-bi,
        &-ai {
            @include palette(font light gray-3);
        }

        &-select:hover,
        &-ai--interactive:hover {
            cursor: pointer;
            @include palette(font light gray-4);

            svg {
                @include palette(bg dark base, font light gray-3);
                @include rounded(a 2);
                @include spaces(p 1);
                box-shadow: 0 0 0.25rem palette(dark, base);
                max-height: fit-content;
                //transform: scale(1.3);

                @include theme(dark) {
                    @include palette(bg dark base-dark);
                    box-shadow: 0 0 0.25rem palette(dark, base-dark);
                }
            }
        }
    }

    &:focus-within {
        #{$content} {
            @include palette(border main gray-2);

            @include theme(dark) {
                @include palette(border main gray-3);
            }

            &-bi,
            &-ai {
                @include palette(font light gray-3);
                //@include palette(font main gray-3);

                @include theme(dark) {
                    @include palette(font light gray);
                }
            }
        }
    }

    &--#{danger} {
        #{$content} {
            @include palette(border light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(border main danger);
            }
        }
    }

    @each $key in $apply-select-roles {
        &--#{$key} #{$content}--keep {
            @include palette(border main $key);
            //@include palette(border main $key);

            @include theme(dark) {
                @include palette(border main $key);
            }
        }

        &--#{$key}:focus-within {
            #{$content} {
                @include palette(border main $key);

                @include theme(dark) {
                    @include palette(border main $key);
                }

                &-bi,
                &-ai {
                    @include palette(font light $key);
                    //@include palette(font dark $key);

                    @include theme(dark) {
                        @include palette(font light $key);
                    }
                }
            }
        }
    }
}

// hint
@mixin hint-roles($hint) {
    #{$hint} {
        @include palette(font light base-dark);
        //@include palette(font main gray-3);

        @include theme(dark) {
            @include palette(font light gray-2);
        }
    }

    &:focus-within {
        #{$hint} {
            @include palette(font main base-dark);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$hint} {
            @include palette(font light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font light danger);
            }
        }
    }

    @each $key in $apply-select-roles {
        &--#{$key}:focus-within {
            #{$hint} {
                @include palette(font light $key);
                //@include palette(font main $key);

                @include theme(dark) {
                    @include palette(font light $key);
                }
            }
        }
    }
}

.select {
    $parent: &;
    $label: none;
    $content: none;
    $hint: none;

    align-items: flex-start;
    border-style: none;
    display: flex;
    flex-direction: column;
    position: relative;

    &--active {
        .select__options {
            transform: translateY(100%) scaleY(1);
        }
    }

    &--fit {
        max-width: fit-content;
    }

    /* &:disabled {
        opacity: 0.6;
    } */

    &__label {
        $label: &;

        //@include spaces(py 8);
        @include fonts(line 24);
        cursor: pointer;
        font-weight: 600;
    }

    &__content {
        $content: &;

        @include rounded(a 6);
        @include spaces(b 1, px 2);
        border-style: solid;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 100%;
        height: 4rem;
        font-weight: 500;

        &--disabled {
            opacity: .6;
        }

        &--select {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        span {
            @include fonts(line 9.2);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            width: 100%;

            &--bg {
                @include palette(bg light accent);
                @include rounded(a 16);
                @include spaces(p 2);
            }
        }

        .content__select--input
        // input
        {
            @include palette(font light base-dark);
            //@include palette(font main gray-3);
            @include spaces(p 6);
            background: transparent;
            border-style: none;
            outline: none;
            flex-grow: 1;
            width: 0;
            cursor: default;

            @include theme(dark) {
                @include palette(font main gray);
            }
        }

        .content__select--input {
            //width: 100%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        input::placeholder {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        select {
            appearance: none;
        }

        &-select {
            @include spaces(mr 8, w 14, h 14);
            @include palette(font light gray-3);
            display: flex;
            pointer-events: none;
            user-select: none;
            //position: absolute;

            &--ai {
                right: 3rem;
            }

            &:hover {
                @include palette(font light gray-4);
            }

            &--disabled {
                display: none;
            }
        }

        &__select {
            height: 0;
            width: 0;
            opacity: 0;
        }

        &-bi,
        &-ai {
            @include spaces(w 20, h 20);
            border-radius: 50%;
        }

        &-bi {
            @include spaces(ml 8);
        }

        &-ai {
            @include spaces(mr 8);
        }

        &--hidden {
            display: none;
        }
    }

    &-bi,
    &-ai {
        @include spaces(w 20, h 20);
        border-radius: 50%;
    }

    &-ai {
        @include spaces(mr 4);
    }

    &__hint {
        $hint: &;

        @include fonts(size 6.5);
        @include spaces(my 1);
        font-weight: 500;
        z-index: 1;
    }

    &__reserved {
        @include spaces(h 10, mt 1);
    }

    &__options {
        @include palette(bg light accent, font main base-dark);
        @include rounded(a 4);
        text-decoration: none;
        box-shadow: 0 1px 5px 0 #000;
        max-height: 20rem;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        transform: translateY(100%) scaleY(0);
        transition: transform 0.25s;
        width: 100%;
        z-index: 1000;

        &__item {
            @include spaces(g 8, py 6, px 4, m 6);
            @include rounded(a 4);
            display: flex;
            align-items: center;
            transition: background-color 0.25s, color 0.25s;

            cursor: default;

            & > div {
                display: flex;
                flex-direction: column;

                span {
                    font-weight: 600;
                }

                h5 {
                    @include palette(font light gray-4);
                }
            }

            &:hover {
                @include palette(bg light gray);
            }

            &.selected {
                background-color: rgba($gray, 0.75);
                color: $primary;
            }
        }

        &--top {
            bottom: 19.7rem;
            left: 0;
            transform-origin: bottom center;
        }
        &--bottom {
            bottom: -0.3rem;
            left: 0;
            transform-origin: top center;
        }
        &--left {
            flex-direction: row;
        }
        &--right {
            flex-direction: row-reverse;
        }
        &--center {
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
    }

    // label
    @include label-roles($label);
    // content
    @include content-roles($content);
    // hint
    @include hint-roles($hint);
}
