@import '@styles';

.home-view {
    &__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
        width: 100%;

        & > section {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;
        }

        & > article {
            height: 9rem;

            @include screen(xl) {
                height: 8rem;
            }
        }
    }

    &__loading {
        @include palette(bg light accent);
        @include spaces(g 16, px 12, py 16);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        width: 100%;
        height: 100vh;
    }

    &__section-1 {
        @include palette(bg alert 100);
        display: flex;
        flex-direction: column;
        position: relative;

        @include screen(xl) {
            @include spaces(px 32, pb 32);
            @include rounded(b 16);
        }

        &__section {
            @include spaces(g 10, py 32, px 10);
            display: flex;
            flex-direction: column;
            min-height: 65rem;
            max-height: 70rem;
            height: 100%;

            @include screen(md) {
                min-height: 60rem;
                max-height: 70rem;
            }

            @include screen(xl) {
                @include spaces(p 16, pb 0, mw 750, mx auto);
                display: grid;
                grid-template-columns: 1.1fr 1fr;
                align-items: center;
                justify-content: center;
                min-height: 40rem;
                max-height: 50rem;
                height: 100%;
            }

            @include screen(xl4) {
                @include spaces(mw 850);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: flex-start !important;

            @include screen(xl) {
                @include spaces(pl 16);
            }

            & > article {
                height: 9rem;

                @include screen(xl) {
                    height: 0;
                }
            }
        }

        &__information {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            width: 100%;
            position: relative;

            @include screen(xl) {
                @include spaces(pb 16);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;
                width: 80%;
            }

            @include screen(xl4) {
                @include spaces(pb 16);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: flex-start;
                width: 80%;
            }

            @include screen(xl5) {
                @include spaces(pb 8);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: flex-start;
                width: 70%;
            }

            &__title {
                @include spaces(g 8, pb 8);
                display: flex;
                flex-direction: column;
                width: 100%;

                @include screen(xl) {
                    @include spaces(g 8);
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                & > div {
                    @include spaces(g 12);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    z-index: 1;

                    @include screen(xl) {
                        align-items: flex-start;
                        z-index: 0;
                    }

                    & > h1 {
                        @include fonts(size 18, line 24);
                        text-align: center;
                        font-weight: 800;

                        @include screen(xl) {
                            @include fonts(size 20, line 26);
                            text-align: start;
                        }

                        @include screen(xl3) {
                            @include fonts(size 24, line 32);
                            text-align: start;
                        }
                    }

                    & > h3 {
                        @include fonts(size 9, line 16);
                        text-align: center;

                        @include screen(xl) {
                            @include fonts(size 12, line 18);
                            text-align: justify;
                        }
                    }

                    & > a {
                        text-decoration: none;
                    }

                    .button--fill {
                        @include spaces(g 8);
                        display: flex;
                        align-items: center;
                        box-shadow: 5px 16px 43px 0px rgba(0, 0, 0, 0.3);
                    }
                }
            }

            &__content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                width: 100%;

                @include screen(xl) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    position: relative;
                    width: 100%;
                }
            }
        }

        &__icons {
            &::after {
                content: '';
                background-image: url('../../assets/images/bg-rounded.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                height: 546px;
                width: 546px;
                bottom: unset;
                right: -15%;

                @include screen(xs) {
                    bottom: 0%;
                    right: unset;
                }

                @include screen(md) {
                    bottom: 0%;
                    right: 15%;
                }

                @include screen(xl) {
                    display: block;
                    right: unset;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    &__banner-coins {
        @include spaces(px 0);
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        background-color: transparent;
        position: relative;
        transform: translate(0%, -10%);
        width: 100%;

        @include screen(sm) {
            transform: translate(0%, -20%);
        }

        @include screen(lg) {
            transform: translate(0%, -10%);
        }

        @include screen(xl) {
            @include spaces(px 42);
            bottom: calc(-10% - 5rem);
            transform: translate(0%, 0%);
            flex-direction: column;
            position: absolute;
        }

        & > div {
            @include palette(bg light accent, border light gray);
            @include spaces(p 20, g 12);
            @include rounded(a 8);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 5px 113px 108px 3px rgba(0, 0, 0, 0.4);
            //max-width: max-content;
            min-width: fit-content;
            width: 100%;

            & > h3 {
                @include fonts(size 10, line 12);
                font-weight: 700;
            }
        }

        &__coin {
            @include spaces(g 20);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            @include screen(xl) {
                flex-direction: row;
            }
        }
    }

    &__section-2 {
        @include spaces(pt 8, pb 0, px 0);
        @include spaces(g 8);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        position: relative;

        @include screen(xl) {
            @include spaces(py 80, px 0);
        }

        &__section {
            @include spaces(g 0, py 16, px 10);
            display: flex;
            flex-direction: column;
            min-height: 65rem;
            max-height: 70rem;
            height: 100%;

            @include screen(md) {
                min-height: 60rem;
                max-height: 70rem;
            }

            @include screen(xl) {
                @include spaces(p 16, pb 0, mw 650, mx auto);
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                justify-items: flex-end;
                min-height: 40rem;
                max-height: 50rem;
                height: 100%;
            }

            @include screen(xl4) {
                @include spaces(mw 750);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: flex-start !important;

            @include screen(xl) {
                @include spaces(pl 16);
            }

            & > article {
                height: 9rem;

                @include screen(xl) {
                    height: 0;
                }
            }
        }

        &__information {
            @include spaces(pt 32);
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            width: 100%;
            position: relative;

            @include screen(xl) {
                @include spaces(pb 16, pt 0);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;
                width: 80%;
            }

            @include screen(xl4) {
                @include spaces(pb 16);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: flex-start;
                width: 80%;
            }

            @include screen(xl5) {
                @include spaces(pb 8);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: flex-start;
                width: 70%;
            }

            &__title {
                @include spaces(g 8, pb 8);
                display: flex;
                flex-direction: column;
                width: 100%;

                @include screen(xl) {
                    @include spaces(g 8);
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                & > div {
                    @include spaces(g 12);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    z-index: 1;

                    @include screen(xl) {
                        align-items: flex-start;
                        z-index: 0;
                    }

                    & > h1 {
                        @include fonts(size 16, line 20);
                        text-align: center;
                        font-weight: 800;

                        & > span {
                            @include palette(font success 500);
                        }

                        @include screen(xl) {
                            @include fonts(size 18, line 24);
                            text-align: start;
                        }

                        @include screen(xl3) {
                            @include fonts(size 20, line 24);
                            text-align: start;
                        }
                    }

                    & > h3 {
                        @include fonts(size 9, line 16);
                        text-align: center;
                        text-transform: uppercase;

                        @include screen(xl) {
                            @include fonts(size 10, line 18);
                            text-align: justify;
                        }
                    }

                    & > h2 {
                        @include palette(font accent 500);
                        @include fonts(size 9, line 16);
                        text-align: center;

                        @include screen(xl) {
                            @include fonts(size 10, line 18);
                            text-align: justify;
                        }
                    }

                    & > a {
                        text-decoration: none;
                    }

                    .button--fill {
                        @include spaces(g 8);
                        display: flex;
                        align-items: center;
                        box-shadow: 5px 16px 43px 0px rgba(0, 0, 0, 0.3);
                    }
                }
            }

            &__content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                width: 100%;

                @include screen(xl) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    position: relative;
                    width: 100%;
                }
            }
        }

        &__phone {
            &::after {
                content: '';
                background-image: url('../../assets/images/bg-phone.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                height: 546px;
                width: 546px;
                display: block;

                @include screen(md) {
                    width: auto;
                    background-position: center;
                }

                @include screen(xl) {
                    width: 546px;
                }
            }
        }
    }

    &__section-2-1 {
        @include spaces(pt 8, py 32, pb 0, px 0, b 1);
        @include palette(bg accent 0, border accent 100);
        @include rounded(a 32);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        position: relative;

        @include screen(xl) {
            @include spaces(py 40, px 0);
        }

        &__section {
            @include spaces(g 0, py 16, px 10);
            display: flex;
            flex-direction: column-reverse;
            min-height: 65rem;
            max-height: 70rem;
            height: 100%;

            @include screen(md) {
                min-height: 60rem;
                max-height: 70rem;
            }

            @include screen(xl) {
                @include spaces(p 16, pb 0, mw 650, mx auto);
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                justify-items: flex-end;
                min-height: 40rem;
                max-height: 50rem;
                height: 100%;
            }

            @include screen(xl4) {
                @include spaces(mw 750);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: flex-start !important;

            @include screen(xl) {
                @include spaces(pl 16);
            }

            & > article {
                height: 9rem;

                @include screen(xl) {
                    height: 0;
                }
            }
        }

        &__information {
            @include spaces(pt 32);
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            width: 100%;
            position: relative;

            @include screen(xl) {
                @include spaces(pb 16, pt 0);
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                flex-grow: 1;
                width: 80%;
            }

            @include screen(xl4) {
                @include spaces(pb 16);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                width: 80%;
            }

            @include screen(xl5) {
                @include spaces(pb 8);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                width: 70%;
            }

            &__title {
                @include spaces(g 8, pb 8);
                display: flex;
                flex-direction: column;
                width: 100%;

                @include screen(xl) {
                    @include spaces(g 8);
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                & > div {
                    @include spaces(g 12);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    z-index: 1;

                    @include screen(xl) {
                        align-items: flex-start;
                        z-index: 0;
                    }

                    & > h1 {
                        @include fonts(size 16, line 20);
                        text-align: center;
                        font-weight: 800;

                        & > span {
                            @include palette(font success 500);
                        }

                        @include screen(xl) {
                            @include fonts(size 18, line 24);
                            text-align: start;
                        }

                        @include screen(xl3) {
                            @include fonts(size 20, line 24);
                            text-align: start;
                        }
                    }

                    & > h3 {
                        @include fonts(size 9, line 16);
                        text-align: center;
                        text-transform: uppercase;

                        @include screen(xl) {
                            @include fonts(size 10, line 18);
                            text-align: justify;
                        }
                    }

                    & > h2 {
                        @include palette(font accent 500);
                        @include fonts(size 9, line 16);
                        text-align: center;

                        @include screen(xl) {
                            @include fonts(size 10, line 18);
                            text-align: justify;
                        }
                    }

                    & > a {
                        text-decoration: none;
                    }

                    .button--fill {
                        @include spaces(g 8);
                        display: flex;
                        align-items: center;
                        box-shadow: 5px 16px 43px 0px rgba(0, 0, 0, 0.3);
                    }
                }
            }

            &__content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                width: 100%;

                @include screen(xl) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    position: relative;
                    width: 100%;
                }
            }
        }

        &__phone {
            &::after {
                content: '';
                background-image: url('../../assets/images/bg-wallet.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                height: 546px;
                width: 546px;
                display: block;

                @include screen(md) {
                    width: auto;
                    background-position: center;
                }

                @include screen(xl) {
                    width: 546px;
                }
            }
        }
    }

    &__section-2-2 {
        @include spaces(pt 8, py 32, pb 0, px 0);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        position: relative;

        @include screen(xl) {
            @include spaces(py 40, px 0);
        }

        &__section {
            @include palette(bg accent 0, border accent 100);
            @include spaces(g 0, py 16, px 10, b 1);
            display: flex;
            flex-direction: column;
            min-height: 65rem;
            max-height: 70rem;
            height: 100%;
            // @include rounded(a 32);
            border-radius: 64px;

            // border: 10px solid;
            // border-image-slice: 1;
            // border-width: 5px;
            // border: 16px solid;

            // border-image-source: linear-gradient(
            //     36.99deg,
            //     #ffd200 -6.62%,
            //     #ffd200 -6.61%,
            //     #4bba36 93.16%
            // );

            @include screen(md) {
                min-height: 60rem;
                max-height: 70rem;
            }

            @include screen(xl) {
                @include spaces(p 16, p 0, mw 650, mx auto);
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                justify-items: flex-end;
                min-height: 40rem;
                max-height: 45rem;
                height: 100%;
            }

            @include screen(xl4) {
                @include spaces(mw 750);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: flex-start !important;

            @include screen(xl) {
                @include spaces(pl 16);
            }

            & > article {
                height: 9rem;

                @include screen(xl) {
                    height: 0;
                }
            }
        }

        &__information {
            @include spaces(pt 32);
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            width: 100%;
            position: relative;

            @include screen(xl) {
                @include spaces(pb 16, pt 0);
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                flex-grow: 1;
                width: 80%;
            }

            @include screen(xl4) {
                @include spaces(pb 16);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                width: 80%;
            }

            @include screen(xl5) {
                @include spaces(pb 8);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                width: 70%;
            }

            &__title {
                @include spaces(g 8, pb 8);
                display: flex;
                flex-direction: column;
                width: 100%;

                @include screen(xl) {
                    @include spaces(g 8);
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                & > div {
                    @include spaces(g 12);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    z-index: 1;

                    @include screen(xl) {
                        align-items: flex-start;
                        z-index: 0;
                    }

                    & > h1 {
                        @include fonts(size 16, line 20);
                        text-align: center;
                        font-weight: 800;

                        & > span {
                            @include palette(font success 500);
                        }

                        @include screen(xl) {
                            @include fonts(size 18, line 24);
                            text-align: start;
                        }

                        @include screen(xl3) {
                            @include fonts(size 20, line 24);
                            text-align: start;
                        }
                    }

                    & > h3 {
                        @include fonts(size 9, line 16);
                        text-align: center;
                        text-transform: uppercase;

                        @include screen(xl) {
                            @include fonts(size 10, line 18);
                            text-align: justify;
                        }
                    }

                    & > h2 {
                        @include palette(font accent 500);
                        @include fonts(size 9, line 16);
                        text-align: center;

                        @include screen(xl) {
                            @include fonts(size 10, line 18);
                            text-align: justify;
                        }
                    }

                    & > a {
                        text-decoration: none;
                    }

                    .button--fill {
                        @include spaces(g 8);
                        display: flex;
                        align-items: center;
                        box-shadow: 5px 16px 43px 0px rgba(0, 0, 0, 0.3);
                    }
                }
            }

            &__content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                width: 100%;

                @include screen(xl) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    position: relative;
                    width: 100%;
                }
            }
        }

        &__phone {
            &::after {
                content: '';
                background-image: url('../../assets/images/bg-cash.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                height: 546px;
                width: 546px;
                display: block;

                @include screen(md) {
                    width: auto;
                    background-position: center;
                }

                @include screen(xl) {
                    width: 546px;
                    transform: translateY(15%);
                }

                @include screen(xl4) {
                    width: 568px;
                    height: 700px;
                    transform: translateY(0%);
                }
            }
        }
    }

    &__section-3 {
        @include spaces(g 8, pt 8, pb 0, px 0);
        @include palette(bg accent 0);
        @include rounded(a 16);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        position: relative;

        @include screen(xl) {
            @include spaces(py 80, px 0);
        }

        &__section {
            @include spaces(g 0, py 16, px 10);
            display: flex;
            flex-direction: column;
            min-height: 65rem;
            max-height: 70rem;
            height: 100%;

            @include screen(md) {
                min-height: 60rem;
                max-height: 70rem;
            }

            @include screen(xl) {
                @include spaces(p 16, pb 0, mw 650, mx auto);
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                justify-items: flex-end;
                min-height: 40rem;
                max-height: 50rem;
                height: 100%;
            }

            @include screen(xl4) {
                @include spaces(mw 750);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: flex-start !important;

            @include screen(xl) {
                @include spaces(pl 16);
            }

            & > article {
                height: 9rem;

                @include screen(xl) {
                    height: 0;
                }
            }
        }

        &__information {
            @include spaces(pt 32);
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            width: 100%;
            position: relative;

            @include screen(xl) {
                @include spaces(pb 16, pt 0);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;
                width: 80%;
            }

            @include screen(xl4) {
                @include spaces(pb 16);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: flex-start;
                width: 80%;
            }

            @include screen(xl5) {
                @include spaces(pb 8);
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: flex-start;
                width: 70%;
            }

            &__title {
                @include spaces(g 8, pb 8);
                display: flex;
                flex-direction: column;
                width: 100%;

                @include screen(xl) {
                    @include spaces(g 8);
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                & > div {
                    @include spaces(g 12);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    z-index: 1;

                    @include screen(xl) {
                        align-items: flex-start;
                        z-index: 0;
                    }

                    & > h1 {
                        @include fonts(size 16, line 20);
                        text-align: center;
                        font-weight: 800;

                        & > span {
                            @include palette(font success 500);
                        }

                        @include screen(xl) {
                            @include fonts(size 18, line 24);
                            text-align: start;
                        }

                        @include screen(xl3) {
                            @include fonts(size 20, line 24);
                            text-align: start;
                        }
                    }

                    & > h3 {
                        @include fonts(size 9, line 16);
                        text-align: center;

                        @include screen(xl) {
                            @include fonts(size 12, line 18);
                            text-align: justify;
                        }
                    }

                    & > a {
                        text-decoration: none;
                    }

                    .button--fill {
                        @include spaces(g 8);
                        display: flex;
                        align-items: center;
                        box-shadow: 5px 16px 43px 0px rgba(0, 0, 0, 0.3);
                    }
                }
            }

            &__content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                width: 100%;

                @include screen(xl) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    position: relative;
                    width: 100%;
                }
            }
        }

        &__phone {
            &::after {
                content: '';
                background-image: url('../../assets/images/bg-phone.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                height: 546px;
                width: 546px;
                display: block;

                @include screen(md) {
                    width: auto;
                    background-position: center;
                }

                @include screen(xl) {
                    width: 546px;
                }
            }
        }
    }

    &__section-4 {
        @include spaces(py 32, px 0, g 8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;

        &__title {
            @include spaces(g 8, p 12);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            & > h1 {
                @include fonts(size 16, line 18);
                font-weight: 800;
                width: 100%;

                @include screen(xl) {
                    @include fonts(size 20, line 22);
                    width: 80%;
                }
            }

            & > span {
                @include palette(font light gray-4);
                width: 80%;
            }
        }

        &__container {
            @include spaces(g 16, px 0);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 80%;

            @include screen(xl) {
                @include spaces(px 16);
                width: 100%;
            }

            @include screen(xl4) {
                @include spaces(px 32);
                width: 80%;
            }

            &__section-1 {
                @include spaces(g 16, px 0, mx auto);
                display: flex;
                flex-direction: column;

                @include screen(lg) {
                    @include spaces(px 32);
                }

                @include screen(xl) {
                    @include spaces(px 32);
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            &__section-2 {
                @include spaces(g 16, px 0);
                display: flex;
                flex-direction: column;

                @include screen(lg) {
                    @include spaces(px 32);
                }

                @include screen(xl) {
                    @include spaces(px 32);
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            &__card {
                @include spaces(g 16, p 16, mx auto);
                @include palette(bg accent 0);
                @include rounded(a 16);
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                @include screen(xl) {
                    @include spaces(px 32);
                }

                & > div {
                    @include spaces(g 8);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    & > h3 {
                        @include fonts(size 12, line 14);
                        @include palette(font dark primary);
                        font-weight: 800;
                    }

                    & > div {
                        @include spaces(g 4);
                        display: flex;
                        flex-direction: column;

                        & > span {
                            @include spaces(g 4);
                            @include palette(font light gray-4);
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            text-align: center;

                            & > i {
                                @include spaces(w 14, h 14);
                            }
                        }
                    }

                    & > span {
                        @include palette(font light gray-4);
                        text-align: center;
                    }
                }
            }
        }

        &__steps {
            @include spaces(g 16, p 12);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            & > div {
                @include spaces(g 32, px 12);
                display: flex;
                flex-direction: column;
                width: 80%;

                & > div {
                    @include spaces(g 12);
                    display: flex;
                    align-items: center;

                    & > h1 {
                        @include spaces(pr 12);
                        @include fonts(size 20);
                        font-weight: 800;
                    }

                    & > div {
                        @include spaces(g 4);
                        display: flex;
                        flex-direction: column;

                        & > h3 {
                            @include fonts(size 12, line 14);
                            font-weight: 800;
                        }

                        & > span {
                            @include palette(font light gray-4);
                        }
                    }
                }
            }

            .button--fill {
                @include spaces(g 4, mt 8);
                @include palette(font light accent);
                display: flex;
                align-items: flex-end;
                box-shadow: 0 0 0 0;
                font-weight: 600;
            }
        }
    }

    &__banner {
        position: relative;

        &__main {
            @include spaces(g 16, p 32, lh 200);
            @include rounded(a 8);
            @include palette(bg main success);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: relative;
            overflow: hidden;
            width: 100%;

            @include screen(xl) {
                @include spaces(g 8, px 32, py 0, mh 100, lh 100);
                @include rounded(a 0);
                flex-direction: row;
            }

            & > span {
                display: none;

                @include screen(xl) {
                    @include palette(bg dark success);
                    @include spaces(mw 200, lw 150, mh 150, lh 200);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                }

                & > span {
                    @include spaces(p 8, mw 150, lw 80, mh 80, lh 150);
                    @include palette(bg dark success-1);
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                }
            }

            & > h1 {
                @include spaces(pl 16);
                @include fonts(size 14, line 16);
                @include palette(font light accent);
                font-weight: 800;
            }
        }

        &__store {
            @include spaces(g 8);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            z-index: 1;

            @include screen(xl) {
                @include spaces(g 8);
                flex-direction: row;
            }

            @include screen(xl3) {
                @include spaces(g 8);
                flex-direction: row;
            }

            & > a {
                text-decoration: none;
            }
        }

        &__icon {
            //display: none;
            position: absolute;
            bottom: 0;
            transform: translate(-25%, 0%);
            width: 100%;

            @include screen(sm) {
                width: 40%;
            }

            @include screen(md) {
                width: 40%;
            }

            @include screen(lg) {
                width: 40%;
            }

            @include screen(xl) {
                width: 35%;
                transform: translate(0%, 0%);
            }

            @include screen(xl4) {
                width: 35%;
            }
        }
    }

    &__banner-clock {
        @include spaces(px 0);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        position: absolute;
        transform: translate(0%, 0%);
        //top: calc(-5% - 1rem);
        width: 100%;
        position: relative;

        @include screen(xl) {
            @include spaces(px 42);
            transform: translate(0%, 60%);
        }

        & > div {
            @include spaces(py 12, px 20, g 8);
            @include rounded(a 16);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #374151;
            box-shadow: 5px 5px 15px 0px palette(light, gray-1);
            min-width: fit-content;
            width: 100%;
            height: auto;

            @include screen(xl) {
                @include spaces(g 12);
                flex-direction: row;
                justify-content: space-between;
                height: 213px;
            }

            & > a {
                text-decoration: none;
            }
        }

        &__icon {
            //position: absolute;
            transform: translate(0%, -20%);

            @include screen(xl) {
                transform: translate(0%, -15%);
            }
        }

        &__title {
            @include palette(font main accent);
            @include spaces(g 8);
            @include spaces(g 8);
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;

            @include screen(xl) {
                width: 50%;
            }

            & > h3 {
                @include fonts(size 14, line 16);
                text-align: center;
                font-weight: 700;

                @include screen(xl) {
                    @include fonts(size 18, line 20);
                    text-align: start;
                }
            }

            & > span {
                text-align: center;

                @include screen(xl) {
                    text-align: start;
                }
            }

            &--link {
                @include palette(font main success);
                font-weight: 800;
            }
        }

        .button--fill {
            @include spaces(g 4, mt 8);
            @include palette(font light accent);
            display: flex;
            align-items: center;
            box-shadow: 0 0 0 0;
            font-weight: 600;
        }
    }

    &__section-4-1 {
        @include palette(bg light accent);
        @include spaces(px 32, py 50, lh 320);
        @include rounded(a 16);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        height: 100%;

        @include screen(xl) {
            @include spaces(pt 80);
        }

        &__container {
            @include spaces(g 16, py 50);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            @include screen(xl) {
                @include spaces(py 32);
            }
        }

        &__title {
            @include spaces(g 12);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            & > h1 {
                @include fonts(size 20, line 22);
                font-weight: 800;
            }

            & > span {
                @include palette(font light gray-3);
            }
        }

        &__wallets {
            @include spaces(g 16);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include screen(xl) {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }
        }

        &__wallet {
            @include spaces(g 4);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__icon {
                @include spaces(p 8, w 60, h 60);
                @include palette(bg light info);
                border-radius: 50%;

                & > svg {
                    @include spaces(p 4);
                }
            }

            & > span {
                font-weight: 800;
            }
        }
    }

    &__banner-credits {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &__coin {
            opacity: 0;
            z-index: 1;
            width: 10rem;
            position: relative;

            @include screen(xl) {
                transform: translate(0%, 70%);
                width: 15rem;
                opacity: 1;
            }
        }
    }

    &__banner-credit {
        @include spaces(px 0);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: 100%;
        position: relative;

        @include screen(xl) {
            @include spaces(px 42);
        }

        &__phone {
            &::after {
                content: '';

                display: none;

                @include screen(xl) {
                    background-image: url('../../assets/images/bg-middle.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: relative;
                    height: 546px;
                    width: 403px;
                    display: block;
                    width: 403px;
                    transform: translateY(-7%);
                }
            }
        }

        &__phone-1 {
            &::after {
                content: '';
                background-image: url('../../assets/images/bg-home.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                height: 546px;
                width: 403px;
                display: block;
                background-position: center;

                @include screen(md) {
                }

                @include screen(xl) {
                    display: none;
                }
            }
        }

        & > div {
            @include palette(bg main success, border light gray);
            @include spaces(px 20, pb 20, g 12);
            @include rounded(a 16);
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            //overflow: hidden;

            @include screen(xl) {
                @include spaces(px 20, g 0, h 230);
                display: grid;
                grid-template-columns: 1.5fr 1fr;
                justify-items: center;
                align-content: center;
            }

            @include screen(xl3) {
                @include spaces(px 20, g 12, h 230);
                display: grid;
                grid-template-columns: 1.5fr 1fr;
                justify-items: center;
                align-content: center;
            }

            .button--fill {
                @include spaces(g 4, mt 8);
                box-shadow: 0 0 0 0;
                font-weight: 600;
                width: 100%;
            }
        }

        &__title {
            @include spaces(g 8, pt 16);
            display: flex;
            flex-direction: column;

            @include screen(xl) {
                @include spaces(pl 64);
                align-items: flex-start;
            }

            & > a {
                text-decoration: none;
            }

            & > div {
                @include spaces(g 8);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            & > h3 {
                @include palette(font light accent);
                @include fonts(size 16, line 18);
                font-weight: 700;
                text-align: center;

                @include screen(xl) {
                    @include fonts(size 22, line 26);
                    text-align: start;
                }

                @include screen(xl3) {
                    @include fonts(size 24, line 26);
                }

                @include screen(xl4) {
                    @include fonts(size 26, line 30);
                }
            }
        }
    }

    &__section-6 {
        position: relative;

        &__cofre {
            display: none;
            opacity: 0;

            @include screen(xl) {
                display: block;
                position: absolute;
                transform: translate(0%, 25%);
                opacity: 1;
                z-index: 1;
            }

            @include screen(xl3) {
                position: absolute;
                transform: translate(0%, 25%);
                opacity: 1;
                z-index: 1;
            }

            & > svg {
                width: 80%;
            }
        }
    }

    &__section-5 {
        @include spaces(pb 32, px 0, g 8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        background: transparent;

        @include screen(xl) {
            background-image: url('../../assets/images/background-2.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }

        &--bg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            width: 100%;
            background-image: radial-gradient(
                circle 20rem at top center,
                #fff5c8 98%,
                transparent 0
            );
        }

        &__icon {
            transform: translate(0%, -20%);
        }

        &__title {
            @include spaces(g 8, p 12);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            transform: translate(0%, -50%);

            & > h1 {
                @include fonts(size 16, line 18);
                font-weight: 800;
                width: 100%;

                @include screen(xl) {
                    @include fonts(size 20, line 22);
                    width: 80%;
                }
            }

            & > span {
                @include palette(font light gray-4);
                width: 80%;
            }
        }

        &__container {
            @include spaces(g 16, px 0);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 80%;

            @include screen(xl) {
                @include spaces(px 16);
                width: 100%;
            }

            @include screen(xl4) {
                @include spaces(px 32);
                width: 80%;
            }

            &__section-1 {
                @include spaces(g 16, px 0);
                display: flex;
                flex-direction: column;
                width: 100%;

                @include screen(lg) {
                    @include spaces(px 32);
                }

                @include screen(xl) {
                    @include spaces(px 32);
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    width: auto;
                }
            }

            &__section-2 {
                @include spaces(g 16, px 0);
                display: flex;
                flex-direction: column;
                width: 100%;

                @include screen(lg) {
                    @include spaces(px 32);
                }

                @include screen(xl) {
                    @include spaces(px 32);
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    //width: auto;
                }
            }

            &__card {
                @include spaces(g 16, p 16, mx auto);
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                border-radius: 30px;
                background: rgba(255, 255, 255, 0.8);
                backdrop-filter: blur(25px);

                @include screen(xl) {
                    @include spaces(px 32);
                }

                & > div {
                    @include spaces(g 8);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-end;

                    & > h3 {
                        @include fonts(size 10, line 14);
                        @include palette(font dark primary);
                        font-weight: 800;
                        text-align: center;
                    }

                    & > span {
                        @include palette(font light gray-4);
                        text-align: justify;
                    }
                }

                &__icon {
                    @include spaces(w 45, h 45);

                    @include screen(xl) {
                        @include spaces(w 60, h 60);
                    }

                    & > svg {
                        @include spaces(p 4);
                    }
                }
            }
        }
    }
}
