@import '@styles';

.about-us-view {
    &__main {
        @include spaces(g 16, p 8, pb 32, mb 20, mx auto);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-grow: 1;
        width: 100%;

        @include screen(xl) {
            @include spaces(g 0, p 32);
        }

        & > section {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;
        }
    }

    &__background {
        @include spaces(g 12);
        display: flex;
        justify-content: center;
        width: 100%;
        //position: absolute;

        @include screen(xl) {
            position: absolute;
            left: 0;
        }

        &--1 {
            position: absolute;
            bottom: 90%;
            right: 80%;

            @include screen(xl) {
                //position: absolute;
                left: 85%;
                top: 0;
                //bottom: 0%;
            }

            & > svg {
                @include spaces(w 30, h 30);
            }
        }

        &--2 {
            @include screen(xl) {
                position: absolute;
                left: 85%;
                top: -5%;
                //bottom: 0%;
            }

            & > svg {
                @include spaces(w 100, h 100);
            }
        }

        &--3 {
            position: absolute;
            top: -5%;
            left: 70%;

            @include screen(xl) {
                left: 99%;
                top: unset;
                bottom: 22%;
            }

            & > svg {
                @include spaces(w 30, h 30);
            }
        }

        &--4 {
            position: absolute;
            left: 85%;

            @include screen(xl) {
                position: absolute;
                left: 100%;
            }

            & > svg {
                @include spaces(w 100, h 100);
            }
        }
    }

    &__information {
        @include spaces(g 32, p 32, mx auto);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include screen(xl) {
            @include spaces(g 32, p 32, mx auto);
        }

        & > h1 {
            @include spaces(pb 16);
            @include fonts(size 20, line 22);
            font-weight: 800;
            text-align: center;
            width: 100%;

            @include screen(xl) {
                @include spaces(py 16);
                @include fonts(size 26, line 28);
                width: 50%;
            }
        }

        & > div {
            @include spaces(g 12, py 8);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            @include screen(xl) {
                @include spaces(g 12, px 16);
                width: 80%;
            }

            & > p {
                @include fonts(size 8, line 12);
                @include palette(font light gray-4);
                text-align: center;

                @include screen(xl) {
                    @include fonts(size 12, line 14);
                    text-align: justify;
                }
            }
        }
    }

    &__container {
        @include spaces(g 16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include screen(xl) {
            @include spaces(g 32, pb 32, pt 0, px 16, mx auto);
            width: 100%;
        }

        @include screen(xl3) {
            width: 80%;
        }

        & > h1 {
            @include spaces(py 16);
            @include fonts(size 16, line 20);
            font-weight: 800;
            text-align: center;
            width: 100%;

            @include screen(xl) {
                @include fonts(size 20, line 22);
                width: 50%;
            }
        }
    }

    &__cards {
        @include spaces(g 16);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include screen(xl) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__card {
        @include palette(bg light accent, border light gray);
        @include spaces(g 10, p 12, pt 8, mw 180, lw 125);
        @include rounded(a 12);
        display: flex;
        flex-direction: column;
        width: 100%;
        //box-shadow: 5px 113px 108px 3px rgba(0, 0, 0, 0.4);
        box-shadow: 18px 24px 24px 7px palette(light, gray-1);
        overflow: hidden;

        @include screen(xl) {
            height: 37rem;
        }

        &__image {
            display: flex;
            flex-direction: column;
            width: 100%;
            
            & > img {
                @include rounded(a 12);
                width: 100%;
                height: 20.5rem;
                object-fit: cover;
                object-position: top;
               
            }
        }

        &__title {
            @include spaces(g 4);
            display: flex;
            flex-direction: column;
            width: 100%;

            & > h3 {
                @include fonts(size 12, line 14);
                font-weight: 800;
                text-transform: capitalize;
            }
        }

        &__subtitle {
            @include spaces(g 4);
            display: flex;
            flex-direction: column;
            width: 100%;

            & > h3 {
                text-align: start;
                font-weight: 700;
            }

            & > span {
                justify-self: center;
                text-align: start;
                font-weight: normal;
            }

            & > div {
                @include spaces(g 3);
                display: flex;
                flex-direction: column;
                width: 100%;

                & > span {
                    justify-self: center;
                    text-align: start;
                    font-weight: normal;
                }
            }
        }

        &__social-media {
            @include spaces(g 8);
            display: flex;
            align-items: center;

            & > i {
                @include palette(font dark primary);
                @include fonts(size 16);
            }
        }
    }
}
